import {
    IonContent,
    IonCard,
    IonPage,
    IonRow,
    IonCol,
    IonGrid,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonLoading,
    IonButton,
    IonItem,
    IonInput,
    IonItemDivider,
    IonIcon,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Login.css';
// import { useHistory } from 'react-router';
import HeaderBar from '../components/HeaderBar';

import { PermitsStore } from '../store/PermitsStore';
import dayjs from 'dayjs';
import firebaseApp from '../firebase';
import { shieldCheckmarkSharp } from 'ionicons/icons';
import EditPermit from '../components/EditPermit';
import { useStoreState } from 'pullstate';
import { PermitStore } from '../store/PermitStore';

const fbDb = firebaseApp.firestore();

const Permit = (props) => {
    const [tagNumber, setTagNumer] = useState(false);
    const { editMode, isLoading } = useStoreState(PermitStore);
    const [permit, setPermit] = useState(null);
    const getData = () => {
        PermitStore.update((s) => {
            s.isLoading = !s.isLoading;
        });

        fbDb.collection('licenses')
            .doc(props.match.params.id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    const id = doc.id;
                    const data = doc.data();
                    setPermit({ id, ...data });
                    // console.log({ id, ...data });
                    PermitStore.update((s) => {
                        s.isLoading = !s.isLoading;
                    });
                } else {
                    // doc.data() will be undefined in this case
                    console.log('No such document!');
                    PermitStore.update((s) => {
                        s.isLoading = !s.isLoading;
                    });
                }
            })
            .catch((error) => {
                console.log('Error getting document:', error);
                PermitStore.update((s) => {
                    s.isLoading = !s.isLoading;
                });
            });
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const issueTag = () => {
        console.log('issues');
        PermitStore.update((s) => {
            s.isLoading = !s.isLoading;
        });

        const getPermits = () => {
            fbDb.collection('licenses')
                .get()
                .then((querySnapshot) => {
                    let permitsList = [];
                    let pendingList = [];
                    querySnapshot.forEach((doc) => {
                        const id = doc.id;
                        const data = doc.data();
                        console.log(doc.id, ' => ', doc.data());
                        if (data.licenseNumber) {
                            permitsList.push({ id, ...data });
                        } else if (!data.licenseNumber) {
                            pendingList.push({ id, ...data });
                        }
                    });
                    PermitsStore.update((s) => {
                        s.permits = permitsList;
                    });
                    PermitsStore.update((s) => {
                        s.pending = pendingList;
                    });
                    PermitStore.update((s) => {
                        s.isLoading = !s.isLoading;
                    });
                });
        };

        fbDb.collection('licenses')
            .doc(permit.id)
            .update({ licenseNumber: tagNumber })
            .then(() => {
                console.log('here');
                fbDb.collection('users')
                    .doc(permit.userDoc.uid)
                    .collection('pets')
                    .doc(permit.pet.petId)
                    .update({
                        licenseNumber: tagNumber,
                        permitId: permit.id,
                    });
            })
            .then(() => {
                getPermits();
                getData();
            })
            .catch((err) => console.log(err));
    };

    if (permit && !editMode) {
        return (
            <IonPage>
                <IonLoading isOpen={isLoading} />
                <HeaderBar title={permit && permit.pet.name + ' ' + permit.userDoc.lastName} />
                <IonContent>
                    <IonGrid className='contentArea'>
                        <IonRow>
                            <IonCol>
                                <IonCard mode='ios' style={{ maxWidth: 500, margin: '20px auto' }}>
                                    {permit.pet.image && (
                                        <img
                                            style={{ width: '100%' }}
                                            src={`${permit.pet.image}`}
                                            alt={'pet'}
                                        />
                                    )}
                                    {permit.licenseNumber ? (
                                        <p className='ion-text-center'>
                                            <IonIcon
                                                style={{ fontSize: 40 }}
                                                icon={shieldCheckmarkSharp}
                                                color='warning'
                                            />{' '}
                                            <br />#{permit.licenseNumber}
                                        </p>
                                    ) : (
                                        <p className='ion-text-center'>
                                            <IonIcon
                                                style={{ fontSize: 40 }}
                                                icon={shieldCheckmarkSharp}
                                                color='medium'
                                            />{' '}
                                            <br />
                                            Requires Tag
                                        </p>
                                    )}
                                    <h2 style={{ textAlign: 'center' }}>
                                        Expires:{' '}
                                        {dayjs(permit.licenseExpiry).format('MMM DD, YYYY')}
                                    </h2>
                                    <IonCardHeader className='ion-margin-top'>
                                        <IonCardTitle>
                                            {permit &&
                                                permit.pet.name + ' ' + permit.userDoc.lastName}
                                        </IonCardTitle>
                                        <IonCardSubtitle
                                            style={{ fontSize: '1.25rem', marginTop: 10 }}
                                        >
                                            {permit.pet.species}, {permit.pet.age} years old.
                                        </IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol>
                                                    <IonCardSubtitle style={{ fontSize: '0.8rem' }}>
                                                        Description
                                                    </IonCardSubtitle>
                                                    <p>
                                                        {permit.pet.breed +
                                                            ', ' +
                                                            permit.pet.identifiers}
                                                    </p>
                                                    <p>
                                                        Spayed/Neutered:{' '}
                                                        {permit.pet.neutered ? 'Yes' : 'No'}
                                                    </p>
                                                    <p>
                                                        Chipped:{' '}
                                                        {permit.pet.microchip ? 'Yes' : 'No'}
                                                    </p>
                                                </IonCol>
                                                <IonCol>
                                                    <IonCardSubtitle style={{ fontSize: '0.8rem' }}>
                                                        Owner Information
                                                    </IonCardSubtitle>
                                                    <p>
                                                        {permit.userDoc.firstName +
                                                            ' ' +
                                                            permit.userDoc.lastName}
                                                    </p>
                                                    <p>{permit.userDoc.address}</p>
                                                    <p>
                                                        <a href={`mailto:${permit.email}`}>
                                                            {permit.userDoc.email}
                                                        </a>
                                                    </p>
                                                    <p>{permit.userDoc.phone}</p>
                                                </IonCol>
                                            </IonRow>
                                            <div style={{ height: 20 }} />
                                            <IonRow></IonRow>
                                            <div style={{ height: 20 }} />
                                            <IonRow>
                                                <IonCol>
                                                    <IonCardSubtitle style={{ fontSize: '0.8rem' }}>
                                                        Veterinarian Information
                                                    </IonCardSubtitle>
                                                    <p>{permit.pet.vetName}</p>
                                                    <p>{permit.pet.vetAddress}</p>
                                                </IonCol>
                                            </IonRow>
                                            {!permit.licenseNumber ? (
                                                <IonRow className='ion-margin-top'>
                                                    <IonCol>
                                                        <IonItemDivider className='ion-margin-top'>
                                                            Issue Tag Number
                                                        </IonItemDivider>
                                                        <IonItem className='ion-margin-top'>
                                                            <IonInput
                                                                placeholder='Enter tag number'
                                                                value={tagNumber}
                                                                onIonChange={(e) =>
                                                                    setTagNumer(e.target.value)
                                                                }
                                                            ></IonInput>
                                                        </IonItem>
                                                        <IonButton
                                                            expand='full'
                                                            onClick={() => issueTag()}
                                                            disabled={!tagNumber}
                                                        >
                                                            Issue Tag
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            ) : null}
                                            <IonRow className='ion-margin-top'>
                                                <IonCol>
                                                    <IonButton
                                                        expand='full'
                                                        onClick={() =>
                                                            PermitStore.update((s) => {
                                                                s.editMode = !s.editMode;
                                                            })
                                                        }
                                                    >
                                                        {editMode ? 'Save' : 'Edit'}
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    } else if (permit && editMode) {
        return <EditPermit permit={permit} getData={getData} />;
    } else {
        return <IonLoading />;
    }
};

export default Permit;
