import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonModal,
    IonListHeader,
    IonItem,
    IonAlert,
    IonIcon,
    IonToggle,
    IonText,
    IonLabel,
    IonList,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonInput,
    IonTextarea,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { trashOutline, openOutline, addCircleOutline } from 'ionicons/icons';
import './Page.css';
import firebaseApp from '../firebase';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import { toast } from '../components/toast';
const fbDb = firebaseApp.firestore().collection('municipality');

const Announcements = () => {
    const municipality = 'Brooks';
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [announcements, setAnnouncements] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        details: '',
        link: 'https://www.petlicense.online',
        sendViaSMS: false,
        sendViaEmail: false,
        sendToLanding: true,
    });

    const maxLength = (message.title + message.details + 'Brooks:').length;

    const options = {
        filterType: 'multiselect',
        elevation: 0,
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        },
        selectableRows: 'none',
        customToolbarSelect: () => {},
        // filename: 'permits.csv'
    };
    const columns = [
        {
            name: 'dateCreated',
            label: 'Date',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => moment(value).format('MMMM Do YYYY'),
            },
        },
        {
            name: 'title',
            label: 'Title',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'details',
            label: 'Details',
            options: {
                filter: true,
                sort: true,
                // customBodyRender: (value) => `${value !== 'other' ? 'Home' : 'Other'}`
            },
        },
        {
            name: 'link',
            label: 'Link',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) =>
                    value !== undefined ? (
                        <IonButton fill='clear' href={value} target='_blank'>
                            <IonIcon color='tertiary' icon={openOutline} />
                        </IonButton>
                    ) : null,
            },
        },
        {
            name: 'id',
            label: 'Delete?',
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value) => (
                    <IonButton
                        fill='clear'
                        color='primary'
                        onClick={() => deleteAnnouncementAlert(value)}
                    >
                        <IonIcon icon={trashOutline} />
                    </IonButton>
                ),
            },
        },
    ];
    const getAnnouncements = () => {
        fbDb.doc(municipality)
            .collection('announcements')
            .onSnapshot((querySnapshot) => {
                var announcementsArray = [];
                querySnapshot.forEach((doc) => {
                    const id = doc.id;
                    const data = doc.data();
                    announcementsArray.push({ id, ...data });
                });
                setAnnouncements(announcementsArray);
            });
    };
    const submitAnnouncement = () => {
        if (message.sendViaEmail === true) {
            const sendEmail = firebaseApp.functions().httpsCallable('broadcastEmail');
            sendEmail({
                title: message.title,
                details: message.details,
                link: message.link !== '' ? message.link : null,
            }).then(() => {
                console.log('email request sent');
                toast('Annoucement sent via Email');
            });
        }
        if (message.sendViaSMS === true) {
            const sendText = firebaseApp.functions().httpsCallable('broadcastText');

            sendText({ title: message.title, details: message.details }).then(() => {
                console.log('sms request sent');
                toast('Annoucement sent via SMS');
            });
        }
        if (message.sendToLanding === true) {
            fbDb.doc(municipality)
                .collection('announcements')
                .add({
                    ...message,
                    dateCreated: Date.now(),
                })
                .then(() => {
                    toast('Annoucement sent to landing page');
                    setShowModal(false);
                });
        }
    };
    const deleteAnnouncementAlert = (value) => {
        setSelectedAnnouncement(value);
        setShowDeleteAlert(true);
    };
    const confirmDeleteAnnouncment = () => {
        fbDb.doc(municipality)
            .collection('announcements')
            .doc(selectedAnnouncement)
            .delete()
            .then(() => {
                console.log('Document successfully deleted!');
                getAnnouncements();
            })
            .catch(function (error) {
                console.error('Error removing document: ', error);
            });
    };

    useEffect(() => {
        getAnnouncements();
    }, [municipality]);

    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar color='primary'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Announcements</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Announcements</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard mode='ios'>
                                <IonToolbar>
                                    <IonButtons slot='end'>
                                        <IonButton onClick={() => setShowModal(true)}>
                                            <IonIcon icon={addCircleOutline} />
                                            &nbsp;New
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                                <IonCardHeader>
                                    <IonCardSubtitle>Resident</IonCardSubtitle>
                                    <IonCardTitle>Announcements</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <MUIDataTable
                                        title={'Existing Announcements'}
                                        data={announcements}
                                        columns={columns}
                                        options={options}
                                    />
                                    <br />
                                    <IonButton
                                        expand='block'
                                        color='primary'
                                        onClick={() => setShowModal(true)}
                                    >
                                        Add New Announcement
                                    </IonButton>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonModal
                    mode={'ios'}
                    isOpen={showModal}
                    onDidDismiss={() => {
                        setShowModal(false);
                        setMessage({
                            title: '',
                            details: '',
                            link: 'https://www.petlicense.online',
                            sendViaEmail: false,
                            sendViaSMS: false,
                        });
                    }}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Add New Announcement</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className='ion-padding'>
                        <p className='ion-padding-horizontal'>
                            By default, all Announcements created here will post to your
                            PetLicense.Online landing page. You may also choose to broadcast this
                            announcement via Email and/or SMS in accordance with the communication
                            policy in place.
                        </p>
                        <IonList className='ion-padding-horizontal'>
                            <IonListHeader className='ion-text-uppercase annSection'>
                                Please add the following details:
                            </IonListHeader>
                            <IonItem>
                                <IonLabel position='floating'>Annoucement Title</IonLabel>
                                <IonInput
                                    value={message.title}
                                    onIonChange={(e) =>
                                        setMessage({
                                            ...message,
                                            title: e.detail.value.toUpperCase(),
                                        })
                                    }
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position='floating'>Details</IonLabel>
                                <IonTextarea
                                    value={message.details}
                                    onIonChange={(e) =>
                                        setMessage({ ...message, details: e.detail.value })
                                    }
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position='floating'>Link (edit if necessary)</IonLabel>
                                <IonInput
                                    value={message.link}
                                    onIonChange={(e) =>
                                        setMessage({ ...message, link: e.detail.value })
                                    }
                                />
                            </IonItem>
                            {message.sendViaSMS === true ? (
                                <p className='ion-padding-horizontal textCount'>
                                    {maxLength <= 160 ? (
                                        <IonText
                                            color={
                                                maxLength < 140
                                                    ? 'sucess'
                                                    : maxLength >= 140 && maxLength <= 160
                                                    ? 'warning'
                                                    : 'danger'
                                            }
                                        >
                                            Total message length: {maxLength}/160 characters
                                        </IonText>
                                    ) : maxLength >= 161 ? (
                                        <IonText color='danger'>
                                            Maximum characters allowed for an SMS message exceeded.
                                            Please edit your title, details, or link. Anything
                                            beyong 160 characters will be cut off from your SMS.
                                            Alernatively, send your SMS message separately from
                                            landing page &/or email.
                                        </IonText>
                                    ) : null}
                                </p>
                            ) : null}
                            <div style={{ height: 10 }} />
                            <IonItem>
                                <IonLabel>Post to PetLicense.Online Landing Page?</IonLabel>
                                <IonToggle
                                    checked={message.sendToLanding}
                                    onIonChange={(e) =>
                                        setMessage({ ...message, sendToLanding: e.detail.checked })
                                    }
                                />
                            </IonItem>
                            <div style={{ height: 10 }} />
                            <IonListHeader className='ion-text-uppercase annSection'>
                                Broadcast this message:
                            </IonListHeader>

                            <IonItem>
                                <IonLabel>Send to users via Email?</IonLabel>
                                <IonToggle
                                    checked={message.sendViaEmail}
                                    onIonChange={(e) =>
                                        setMessage({ ...message, sendViaEmail: e.detail.checked })
                                    }
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Send to users via SMS?</IonLabel>
                                <IonToggle
                                    checked={message.sendViaSMS}
                                    onIonChange={(e) =>
                                        setMessage({ ...message, sendViaSMS: e.detail.checked })
                                    }
                                />
                            </IonItem>
                        </IonList>
                        <div className='ion-padding'>
                            <IonButton
                                expand='block'
                                disabled={
                                    message.title !== '' && message.details !== '' ? false : true
                                }
                                onClick={submitAnnouncement}
                            >
                                Submit!
                            </IonButton>
                        </div>
                    </IonContent>
                </IonModal>
                <IonAlert
                    isOpen={showDeleteAlert}
                    onDidDismiss={() => setShowDeleteAlert(false)}
                    cssClass='my-custom-class'
                    header={'Please Confirm'}
                    message={`Click confirm to delete this announcement`}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: (blah) => {
                                console.log('Confirm Cancel: blah');
                            },
                        },
                        {
                            text: 'Okay',
                            handler: () => {
                                confirmDeleteAnnouncment();
                            },
                        },
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

export default Announcements;
