import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonButton,
    IonIcon,
    IonText,
} from '@ionic/react';
import React from 'react';
import MUIDataTable from 'mui-datatables';
import './Page.css';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router';
import { eyeOutline, mailOutline } from 'ionicons/icons';
import dayjs from 'dayjs';
import { PermitsStore } from '../store/PermitsStore';
import PermitModal from '../components/PermitModal';

const Permits = () => {
    const history = useHistory();
    const permits = PermitsStore.useState((s) => s.permits);
    const options = {
        filterType: 'multiselect',
        elevation: 0,
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        },
        selectableRows: 'none',
        customToolbarSelect: () => {},

        // filename: 'permits.csv'
    };
    const handleClick = (rowData) => {
        // console.log(rowData);
        PermitsStore.update((s) => {
            s.activePermit = rowData;
        });
        history.push(`/licenses/${rowData.id}`);
    };
    const columns = [
        {
            name: 'id',
            label: 'View',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, data) => (
                    <IonButton
                        fill='clear'
                        color={'primary'}
                        onClick={() => {
                            // console.log(value, data);
                            handleClick(data.tableData[data.rowIndex]);
                        }}
                        slot='icon-only'
                    >
                        <IonIcon icon={eyeOutline} />
                    </IonButton>
                ),
            },
        },
        {
            name: 'licenseNumber',
            label: 'Tag #',
            options: {
                display: true,
                filter: true,
                sort: true,
            },
        },
        {
            name: 'pet.name',
            label: 'Pet Name',
            options: {
                display: true,
                filter: false,
                sort: false,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].pet.name,
            },
        },
        {
            name: 'pet.type',
            label: 'Pet Type',
            options: {
                display: true,
                filter: false,
                sort: false,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].pet.species,
            },
        },
        {
            name: 'userDoc.phone',
            label: 'Phone',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].userDoc.phone,
            },
        },
        {
            name: 'userDoc.email',
            label: 'Email',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val, data) =>
                    data.tableData[data.rowIndex].userDoc.email ? (
                        <IonButton
                            fill='clear'
                            href={`mailto:${data.tableData[data.rowIndex].userDoc.email}`}
                        >
                            <IonIcon icon={mailOutline} />
                        </IonButton>
                    ) : (
                        'Unavailable'
                    ),
            },
        },
        // {
        //     name: 'pet.neutered',
        //     label: 'Fixed',
        //     options: {
        //         filter: true,
        //         sort: true,
        //         // customBodyRender: (val, data) => console.log(data),
        //     },
        // },
        {
            name: 'address',
            label: 'Location',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].userDoc.address,
            },
        },
        {
            name: 'firstName',
            label: 'First Name',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].userDoc.firstName,
            },
        },
        {
            name: 'lastName',
            label: 'Last Name',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].userDoc.lastName,
            },
        },
        {
            name: 'licenseExpiry',
            label: 'Expires',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (val) => <IonText>{dayjs(val).format('YYYY-MM-DD')}</IonText>,
            },
        },
        {
            name: 'user',
            label: 'user',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        },
    ];

    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar color='primary'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>All Licenses</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>All Licenses</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                {permits ? (
                                    <MUIDataTable
                                        title={'Licenses'}
                                        data={permits}
                                        columns={columns}
                                        options={options}
                                    />
                                ) : null}
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <PermitModal />
        </IonPage>
    );
};

export default Permits;
