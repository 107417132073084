import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
});

const Terms = () => {
    return (
        <Page size='A4' style={styles.page}>
            <View style={styles.section}>
                <View style={{ marginVertical: 10 }}>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 18,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        PLEASE CAREFULLY REVIEW THE TERM & CONDITIONS.
                    </Text>
                    <View style={{ height: 20 }} />
                    <View>
                        <Text style={{ fontSize: 13, marginBottom: 10 }}>
                            All open burning requires a valid burn permit account issued to the
                            landowner.{' '}
                        </Text>
                        <Text style={{ fontSize: 13, marginBottom: 10 }}>
                            No permit is required when burning in an approved container or for
                            cooking and campfires as long as they do not exceed a pile 4 feet tall 4
                            feet wide or 4 feet long.{' '}
                        </Text>
                        <Text style={{ fontSize: 13, marginBottom: 10 }}>
                            Only grass, leaves, and brush are allowed to be burned. Paper can be
                            burned in a metal or masonry constructed burner. No building materials,
                            hazardous waste or any other material creating unreasonable amounts of
                            smoke or air pollutants may not be burned.
                        </Text>
                        <Text style={{ fontSize: 13, marginBottom: 10 }}>
                            Open burning should be conducted between DAWN and DUSK. An open burn
                            should be extinguished completely to ensure the smoldering of material
                            does not persist. You are not allowed to burn at night.
                        </Text>
                        <Text style={{ fontSize: 13, marginBottom: 10 }}>
                            Common sense precautions, such as having someone watching the fire until
                            it is extinguished and assuring smoke does not impact residences or
                            impair vehicular travel should be followed. Open burning should not
                            obscure visibility or create a traffic hazard on any public road.
                        </Text>
                        <Text style={{ fontSize: 13, marginBottom: 10 }}>
                            The Fire Official has the right to prohibit all burning due to periods
                            of smoke dispersion, excessive visibility impairment, or during periods
                            of extreme fire danger where weather conditions that make such fires
                            hazardous or offensive.
                        </Text>
                        <Text style={{ fontSize: 13, marginBottom: 10 }}>
                            All open burning shall be at least fifty (50) feet away from any
                            structure. You must have available any necessary equipment to control
                            the burn and to put out the fire if the need arises. You must completely
                            extinguish the fire before leaving it unattended.
                        </Text>
                        <Text style={{ fontSize: 13, marginBottom: 10 }}>
                            Fires MUST remain under control at ALL times and remain small enough so
                            that it can be easily extinguished at all times. Anyone in violation of
                            the mentioned rules and regulations may be required to extinguish the
                            fire. Continued abuse can result in a fine and/or the loss of any future
                            burn permits being issued to the landowner. You may be responsible for
                            any damage caused by a fire started by your open burning and may be
                            subject to civil penalties from these damages. If you have any questions
                            regarding these guidelines, please contact the Brooks By-law to speak to
                            the on-duty firefighter.
                        </Text>
                    </View>
                    <Text style={{ fontSize: 13, marginBottom: 10 }}>
                        Conditions change daily and permits may be denied under certain weather or
                        predicted weather outlooks (such as high wind or excessive heat) or other
                        hazardous conditions. Therefore, this permit is only good for 24hrs and must
                        be requested on a daily basis.
                    </Text>
                </View>
            </View>
        </Page>
    );
};

export default Terms;
