import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from '@ionic/react';
import { useState } from 'react';

import firebaseApp from '../firebase';
import { PermitStore } from '../store/PermitStore';

const fbDb = firebaseApp.firestore();
const EditPermit = ({ permit, getData }) => {
    const [updatedPermit, setUpdatedPermit] = useState(permit);

    const updatePermitAndUser = async () => {
        console.log(updatedPermit);
        PermitStore.update((s) => {
            s.isLoading = true;
        });
        const permitRef = fbDb.collection('licenses').doc(permit.id);

        await permitRef
            .update(updatedPermit)
            .then(() => {
                PermitStore.update((s) => {
                    s.isLoading = false;
                    s.editMode = false;
                });
                getData();
            })
            .catch((error) => {
                PermitStore.update((s) => {
                    s.isLoading = false;
                });
                alert(error);
            });
    };
    return (
        <IonGrid>
            <h1>Edit Permit</h1>
            <IonRow>
                <IonCol>
                    <div style={{ height: '100vh', overflow: 'scroll' }}>
                        <IonItem>
                            <IonLabel position='stacked'>License #</IonLabel>
                            <IonInput
                                value={updatedPermit.licenseNumber}
                                onIonChange={(e) => {
                                    setUpdatedPermit({
                                        ...updatedPermit,
                                        licenseNumber: e.target.value,
                                    });
                                }}
                            />
                        </IonItem>

                        {Object.keys(permit.pet).map((key) => {
                            const value = updatedPermit.pet[key];
                            if (key === 'petId' || key === 'image') return null;
                            if (typeof value === 'boolean') {
                                return (
                                    <IonItem key={key}>
                                        <IonLabel position='stacked'>{key}</IonLabel>
                                        <IonCheckbox
                                            checked={value}
                                            onIonChange={(e) => {
                                                setUpdatedPermit({
                                                    ...updatedPermit,
                                                    pet: {
                                                        ...updatedPermit.pet,
                                                        [key]: e.target.checked,
                                                    },
                                                });
                                            }}
                                        />
                                    </IonItem>
                                );
                            } else {
                                return (
                                    <IonItem key={key}>
                                        <IonLabel position='stacked'>{key}</IonLabel>
                                        <IonInput
                                            value={value}
                                            onIonChange={(e) => {
                                                setUpdatedPermit({
                                                    ...updatedPermit,
                                                    pet: {
                                                        ...updatedPermit.pet,
                                                        [key]: e.target.value,
                                                    },
                                                });
                                            }}
                                        />
                                    </IonItem>
                                );
                            }
                        })}
                        {Object.keys(permit.userDoc).map((key) => {
                            const value = updatedPermit.userDoc[key];
                            if (typeof value === 'boolean' || key === 'uid') {
                                return null;
                            } else {
                                return (
                                    <IonItem key={key}>
                                        <IonLabel position='stacked'>{key}</IonLabel>
                                        <IonInput
                                            value={value}
                                            onIonChange={(e) => {
                                                setUpdatedPermit({
                                                    ...updatedPermit,
                                                    userDoc: {
                                                        ...updatedPermit.userDoc,
                                                        [key]: e.target.value,
                                                    },
                                                });
                                            }}
                                        />
                                    </IonItem>
                                );
                            }
                        })}
                        <div style={{ height: '2rem' }}></div>
                        <IonButton expand='full' onClick={() => updatePermitAndUser()}>
                            Update License
                        </IonButton>
                        <IonButton
                            expand='full'
                            onClick={() =>
                                PermitStore.update((s) => {
                                    s.editMode = false;
                                })
                            }
                            color='danger'
                        >
                            Cancel
                        </IonButton>
                        <div style={{ height: '10rem' }}></div>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default EditPermit;
