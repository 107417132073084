import {
    IonButton,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import React from 'react';
import { PetStore } from '../../../store/PetStore';

const Step4 = () => {
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const petDoc = PetStore.useState((s) => s.petDoc);

    const updatePetDoc = (val, field) => {
        PetStore.update((s) => {
            s.petDoc = { ...s.petDoc, [field]: val };
        });
    };

    return (
        <div className={step === 4 ? 'steps' : 'ion-hide'}>
            <h2 className='ion-padding-horizontal onboardingTitles'>We appreciate the details!</h2>
            <div style={{ height: '20px' }} />
            <div className='ion-padding-horizontal'>
                <p className='ion-text-left'>
                    <strong>
                        Lastly, we need to know about {petDoc.name}'s immunisation history.
                    </strong>
                </p>
            </div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position='stacked' className='ion-text-wrap onboardingLabels'>
                                Is {petDoc.gender === 'male' ? 'he' : 'she'} up-to-date with{' '}
                                {petDoc.gender === 'male' ? 'his' : 'her'} vaccinations, including
                                Rabies and Distemper?
                            </IonLabel>
                            <IonSelect
                                value={petDoc.immunisation}
                                placeholder='Select One'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'immunisation')}
                            >
                                <IonSelectOption value={true}>Yes</IonSelectOption>
                                <IonSelectOption value={false}>No</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel className='ion-text-wrap onboardingLabels' position='stacked'>
                                Veterinarian's Name
                            </IonLabel>
                            <IonInput
                                type='text'
                                value={petDoc.vetName}
                                placeholder='Veterinarian Name'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'vetName')}
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonButton
                className='ion-margin'
                shape='round'
                expand='block'
                onClick={() => {
                    PetStore.update((s) => {
                        s.petOnboardingStep = 5;
                    });
                }}
            >
                {'Continue'}
            </IonButton>
        </div>
    );
};

export default Step4;
