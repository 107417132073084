import { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';
import { Capacitor } from '@capacitor/core';
import firebase from '../firebase';
import { PetStore } from '../store/PetStore';
const PHOTO_STORAGE = 'photos';

export function usePhotoGallery() {
    const [photos, setPhotos] = useState([]);
    const user = firebase.auth().currentUser;
    useEffect(() => {
        const loadSaved = async () => {
            const { value } = await Storage.get({ key: PHOTO_STORAGE });

            const photosInStorage = value ? JSON.parse(value) : [];
            // If running on the web...
            if (!isPlatform('hybrid')) {
                for (let photo of photosInStorage) {
                    const file = await Filesystem.readFile({
                        path: photo.filepath,
                        directory: Directory.Data,
                    });
                    // Web platform only: Load the photo as base64 data
                    photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
                }
            }
            setPhotos(photosInStorage);
        };
        loadSaved();
    }, []);

    const takePhoto = async () => {
        try {
            const cameraPhoto = await Camera.getPhoto({
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
                allowEditing: true,
                quality: 70,
                width: 1000,
                height: 1000,
                preserveAspectRatio: true,
            });
            const fileName = new Date().getTime() + '.jpeg';
            const savedFileImage = await savePicture(cameraPhoto, fileName);
            const newPhotos = [savedFileImage, ...photos];

            setPhotos(newPhotos);

            Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });
        } catch (error) {
            console.log(error);
        }
    };

    const savePicture = async (photo, fileName) => {
        let base64Data;
        // "hybrid" will detect Cordova or Capacitor;
        if (isPlatform('hybrid')) {
            const file = await Filesystem.readFile({
                path: photo.path,
            });
            base64Data = file.data;
        } else {
            base64Data = await base64FromPath(photo.webPath);
        }
        const savedFile = await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Data,
        });

        const b64 = base64FromPath(photo.webPath);

        uploadImage(b64, fileName);

        if (isPlatform('hybrid')) {
            // Display the new image by rewriting the 'file://' path to HTTP
            // Details: https://ionicframework.com/docs/building/webview#file-protocol
            return {
                filepath: savedFile.uri,
                webviewPath: Capacitor.convertFileSrc(savedFile.uri),
            };
        } else {
            // Use webPath to display the new image instead of base64 since it's
            // already loaded into memory
            return {
                filepath: fileName,
                webviewPath: photo.webPath,
            };
        }
    };

    const uploadImage = (b64, fileName) => {
        const storageRef = firebase.storage().ref(`images/${user.email}/${fileName}`);

        b64.then((image) => {
            storageRef
                .putString(image, 'data_url')
                .then(function (snapshot) {
                    console.log('Uploaded a b64! to', snapshot);
                    storageRef
                        .getDownloadURL()
                        .then((url) => {
                            console.log(url);
                            PetStore.update((s) => {
                                s.petDoc = { ...s.petDoc, image: url };
                            });
                            // Now you have valid `imageURL` from async call
                            // var user = auth.currentUser;
                            user.updateProfile({ photoURL: url })
                                .then(function () {
                                    console.log(user);
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch((err) => console.log(err));
        });
    };
    const deletePhoto = async (photo) => {
        PetStore.update((s) => {
            s.petDoc = { ...s.petDoc, image: null };
        });
        //delete from Firebase
        const storageRef = firebase.storage().ref(`images/${user.email}/${photo.filepath}`);
        storageRef
            .delete()
            .then(function () {
                console.log(photo, 'deleted');
            })
            .catch(function (error) {
                console.log(error);
            });
        // console.log(photo);

        // Remove this photo from the Photos reference data array
        const newPhotos = photos.filter((p) => p.filepath !== photo.filepath);

        // Update photos array cache by overwriting the existing photo array
        Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });

        // delete photo file from filesystem
        const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
        await Filesystem.deleteFile({
            path: filename,
            directory: Directory.Data,
        });
        setPhotos(newPhotos);
    };
    return {
        takePhoto,
        photos,
        deletePhoto,
        uploadImage,
    };
}
export async function base64FromPath(path) {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject('method did not return a string');
            }
        };
        reader.readAsDataURL(blob);
    });
}
