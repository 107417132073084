import { Store } from 'pullstate';

export const PetStore = new Store({
    pets: [],
    initialPetDoc: {
        name: '',
        species: '',
        gender: '',
        neutered: false,
        breed: '',
        identifiers: '',
        microchip: null,
        age: null,
        vetName: '',
        vetAddress: '',
        immunisation: false,
    },
    petDoc: {
        name: '',
        species: '',
        gender: '',
        neutered: false,
        breed: '',
        size: '',
        identifiers: '',
        microchip: null,
        age: null,
        vetName: '',
        vetAddress: '',
        immunisation: false,
        image: null,
    },
    petOnboardingModal: false,
    petOnboardingStep: 1,
    context: null,
    petModal: true,
});
