import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonImg,
    IonLoading,
    IonBadge,
} from '@ionic/react';
import firebaseApp from '../firebase';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import fplogo from '../assets/images/petlicense.png';
import {
    shieldCheckmarkSharp,
    shieldCheckmarkOutline,
    logOutOutline,
    logOutSharp,
    pawOutline,
    pawSharp,
    pricetagsOutline,
    pricetagsSharp,
    addCircleOutline,
    addCircleSharp,
} from 'ionicons/icons';
import './Menu.css';
import { PermitsStore } from '../store/PermitsStore';
import { TownStore } from '../store/TownStore';

const Menu = () => {
    const location = useLocation();
    const history = useHistory();
    const logOut = () => {
        firebaseApp.auth().signOut();
        history.push('/login', { direction: 'none' });
    };
    const [showLoading, setShowLoading] = useState(false);
    const pending = PermitsStore.useState((s) => s.pending);
    const alerts = TownStore.useState((s) => s.alerts);
    const appPages = [
        {
            title: 'Pending Tags',
            url: '/pending',
            iosIcon: pricetagsOutline,
            mdIcon: pricetagsSharp,
            count: pending ? pending.length : 0,
        },
        {
            title: 'Licenses',
            url: '/licenses',
            iosIcon: shieldCheckmarkOutline,
            mdIcon: shieldCheckmarkSharp,
        },
        // {
        //     title: 'New Permit',
        //     url: '/new-permit',
        //     iosIcon: addCircleOutline,
        //     mdIcon: addCircleSharp,
        // },
        {
            title: 'Pet Alerts',
            url: '/alerts',
            iosIcon: pawOutline,
            mdIcon: pawSharp,
            count: alerts ? alerts.length : 0,
        },
    ];
    return (
        <IonMenu contentId='main' type='overlay'>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Establishing secure connection...'}
            />
            <IonContent>
                <IonList id='inbox-list'>
                    <IonListHeader>
                        <IonImg src={fplogo} />
                    </IonListHeader>
                    <IonNote>Brooks</IonNote>
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={location.pathname === appPage.url ? 'selected' : ''}
                                    routerLink={appPage.url}
                                    routerDirection='none'
                                    lines='none'
                                    detail={false}
                                >
                                    <IonIcon
                                        slot='start'
                                        color={appPage.color}
                                        ios={appPage.iosIcon}
                                        md={appPage.mdIcon}
                                    />
                                    <IonLabel>{appPage.title}</IonLabel>
                                    {appPage.count ? (
                                        <IonBadge color='danger'>{appPage.count}</IonBadge>
                                    ) : null}
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                </IonList>
                <IonList>
                    {/* <IonMenuToggle autoHide={false}>
                        <IonItem lines='none' routerLink={'/settings'} routerDirection='none'>
                            <IonIcon
                                slot='start'
                                color={'primary'}
                                ios={cogOutline}
                                md={cogSharp}
                            />
                            <IonLabel>Settings</IonLabel>
                        </IonItem>
                    </IonMenuToggle> */}
                    <IonMenuToggle autoHide={false}>
                        <IonItem lines='none' button onClick={logOut}>
                            <IonIcon
                                slot='start'
                                color={'primary'}
                                ios={logOutOutline}
                                md={logOutSharp}
                            />
                            <IonLabel>Log Out</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
