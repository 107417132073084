import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCardHeader,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonToggle,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardSubtitle,
    IonCardContent,
    IonListHeader,
    IonLabel,
    IonInput,
    IonCardTitle,
    IonBadge,
    IonLoading,
    IonCheckbox,
    IonButton,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Page.css';
import { PermitsStore } from '../store/PermitsStore';
import dayjs from 'dayjs';
import firebaseApp from '../firebase';
import CheckoutForm from '../components/CheckoutForm';
const fbDb = firebaseApp.firestore();
// const user = firebaseApp.auth().currentUser;
const New = () => {
    const [showLoading, setShowLoading] = useState(false);
    const permitTypes = PermitsStore.useState((s) => s.permitTypes);
    const [prepaid, setPrepaid] = useState(true);
    const [email, setEmail] = useState(null);
    const [permitType, setPermitType] = useState(null);
    const [newPermit, setNewPermit] = useState({
        licenseExpiry: dayjs(Date.now() + 31556952000).format(),
        licenseNumber: null,
        pet: {
            name: null,
            breed: null,
            color: null,
            gender: null,
            age: null,
            identifiers: null,
            immunisation: false,
            microchip: false,
            neutered: false,
            size: null,
            species: null,
            vetAddress: null,
            vetName: null,
        },
        userDoc: {
            address: null,
            fullAddress: null,
            firstName: null,
            lastName: null,
            phone: null,
        },
    });
    const newPermitLabels = {
        licenseExpiry: 'License Expiry',
        licenseNumber: 'Tag Number',
        pet: {
            name: 'Pet Name',
            breed: 'Breed',
            color: 'Color',
            gender: 'Pet Gender',
            age: 'Pet Age',
            identifiers: 'Pet Identifiers',
            immunisation: 'Pet is Immunised',
            microchip: 'Pet has Microchip',
            neutered: 'Pet is Spayed/Neutered',
            size: 'Pet Size',
            species: 'Pet Species',
            vetAddress: 'Veterinarian Address',
            vetName: 'Veterinarian Name',
        },
        userDoc: {
            address: 'Home Address',
            fullAddress: 'Home Address Again',
            email: 'Owner Email Address',
            firstName: 'Owner First Name',
            lastName: 'Owner Last Name',
            phone: 'Owner Phone Number',
        },
    };
    useEffect(() => {
        console.log(newPermit);
    }, [newPermit]);
    const createNewLicense = async () => {
        setShowLoading(true);
        const licensesRef = fbDb.collection('licenses');
        const newLicense = {
            ...newPermit,
            licenseType: permitType.name,
        };

        await licensesRef
            .add(newLicense)
            .then(() => {
                setShowLoading(false);
            })
            .catch((error) => {
                setShowLoading(false);
                alert(error);
            });
    };
    return (
        <IonPage>
            <IonLoading isOpen={showLoading} />
            <IonHeader>
                <IonToolbar color='primary' mode='ios'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Add New Permit</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Add New Permit</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard mode='ios'>
                                <IonCardHeader>
                                    <IonCardSubtitle>Create a new</IonCardSubtitle>
                                    <IonCardTitle>Pet License</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol sizeXs='12'>
                                                <IonItem>
                                                    <IonLabel>Prepaid? </IonLabel>
                                                    <IonToggle
                                                        checked={prepaid}
                                                        onIonChange={(e) =>
                                                            setPrepaid(e.detail.checked)
                                                        }
                                                    />
                                                </IonItem>
                                                {!prepaid && (
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Email Address for payment
                                                        </IonLabel>
                                                        <IonInput
                                                            value={email}
                                                            onIonChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                        />
                                                    </IonItem>
                                                )}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol sizeXs='12'>
                                                {Object.entries(newPermit).map(([key, value]) => {
                                                    // Check if the value is an object (i.e., a nested object exists)
                                                    if (
                                                        typeof value === 'object' &&
                                                        value !== null
                                                    ) {
                                                        return Object.entries(value).map(
                                                            ([nestedKey, nestedValue]) => {
                                                                const label =
                                                                    newPermitLabels[key][nestedKey];
                                                                if (
                                                                    typeof nestedValue === 'boolean'
                                                                ) {
                                                                    return (
                                                                        <IonItem key={nestedKey}>
                                                                            <IonLabel position='stacked'>
                                                                                {label}
                                                                            </IonLabel>
                                                                            <IonCheckbox
                                                                                checked={
                                                                                    nestedValue
                                                                                }
                                                                                onIonChange={(
                                                                                    e
                                                                                ) => {
                                                                                    setNewPermit({
                                                                                        ...newPermit,
                                                                                        [key]: {
                                                                                            ...newPermit[
                                                                                                key
                                                                                            ],
                                                                                            [nestedKey]:
                                                                                                e
                                                                                                    .target
                                                                                                    .checked,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </IonItem>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <IonItem key={nestedKey}>
                                                                            <IonLabel position='stacked'>
                                                                                {label}
                                                                            </IonLabel>
                                                                            <IonInput
                                                                                value={nestedValue}
                                                                                onIonChange={(
                                                                                    e
                                                                                ) => {
                                                                                    setNewPermit({
                                                                                        ...newPermit,
                                                                                        [key]: {
                                                                                            ...newPermit[
                                                                                                key
                                                                                            ],
                                                                                            [nestedKey]:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </IonItem>
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    } else {
                                                        const label = newPermitLabels[key];
                                                        // If the value is not an object, render the key-value pair as usual
                                                        if (typeof value === 'boolean') {
                                                            return (
                                                                <IonItem key={key}>
                                                                    <IonLabel position='stacked'>
                                                                        {label}
                                                                    </IonLabel>
                                                                    <IonCheckbox
                                                                        checked={value}
                                                                        onIonChange={(e) => {
                                                                            setNewPermit({
                                                                                ...newPermit,
                                                                                [key]: e.target
                                                                                    .checked,
                                                                            });
                                                                        }}
                                                                    />
                                                                </IonItem>
                                                            );
                                                        } else {
                                                            return (
                                                                <IonItem key={key}>
                                                                    <IonLabel position='stacked'>
                                                                        {label}
                                                                    </IonLabel>
                                                                    <IonInput
                                                                        value={value}
                                                                        onIonChange={(e) => {
                                                                            setNewPermit({
                                                                                ...newPermit,
                                                                                [key]: e.target
                                                                                    .value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </IonItem>
                                                            );
                                                        }
                                                    }
                                                })}
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol sizeXs='12' sizeMd='6'>
                                                <IonList>
                                                    <IonListHeader>License Type</IonListHeader>
                                                    <IonRadioGroup
                                                        value={permitType}
                                                        onIonChange={(e) =>
                                                            setPermitType(e.detail.value)
                                                        }
                                                    >
                                                        {permitTypes.map((type, i) => (
                                                            <div key={i}>
                                                                <IonItem
                                                                    aria-label={'Burn permit type'}
                                                                    lines='none'
                                                                >
                                                                    <IonLabel className='ion-text-wrap'>
                                                                        {type.name}{' '}
                                                                        {type.desc ? '-' : ''}{' '}
                                                                        {type.desc}
                                                                    </IonLabel>
                                                                    <IonRadio
                                                                        slot='start'
                                                                        value={type}
                                                                    />
                                                                    <IonBadge slot='end'>
                                                                        ${type.price}
                                                                    </IonBadge>
                                                                </IonItem>
                                                            </div>
                                                        ))}
                                                    </IonRadioGroup>
                                                </IonList>
                                            </IonCol>
                                            <IonCol sizeXs='12' sizeMd='6'>
                                                <IonList>
                                                    {permitType && (
                                                        <>
                                                            <IonListHeader>Payment</IonListHeader>
                                                            <CheckoutForm
                                                                permitType={permitType}
                                                                newPermit={newPermit}
                                                                email={email}
                                                            />
                                                        </>
                                                    )}

                                                    {!permitType && (
                                                        <IonListHeader>
                                                            Select a license type to see payment
                                                            options.
                                                        </IonListHeader>
                                                    )}
                                                </IonList>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default New;
