import React, { useState, useEffect } from 'react';
import {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    IonLoading,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
} from '@ionic/react';
import firebaseApp from 'firebase';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { PermitsStore } from './store/PermitsStore';
import { UserStore } from './store/UserStore';
import { TownStore } from './store/TownStore';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Menu from './components/Menu';
import Permits from './pages/Permits';
import Announcements from './pages/Announcements';
import New from './pages/New';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Success from './pages/Success';
import Permit from './pages/Permit';
import Alerts from './pages/Alerts';
import Pending from './pages/Pending';
import Details from './pages/Details';
import Customers from './pages/Customers';

const fbDb = firebaseApp.firestore();
const App = () => {
    const [showLoading, setShowLoading] = useState(true);
    const [user, loading, error] = useAuthState(firebaseApp.auth());
    const [admin, setAdmin] = useState(false);
    const [ready, setReady] = useState(false);

    const logout = () => {
        firebaseApp.auth().signOut();
        window.location.reload();
    };

    useEffect(() => {
        const getUser = () => {
            if (user) {
                // console.log(user)
                fbDb.collection('users')
                    .doc(user.uid)
                    .get()
                    .then((doc) => {
                        const data = doc.data();
                        UserStore.update((s) => {
                            s.user = data;
                        });
                        if (data.admin === true) {
                            // console.log('admin');
                            setAdmin(true);
                        } else {
                            console.log('not admin');
                        }
                    })
                    .catch((err) => {
                        console.log(err, 'user not admin');
                    });
            }
        };
        const getTown = () => {
            if (user) {
                // console.log(user)
                fbDb.collection('municipality')
                    .doc('Brooks')
                    .get()
                    .then((doc) => {
                        const data = doc.data();
                        TownStore.update((s) => {
                            s.townDoc = data;
                        });
                    })
                    .catch((err) => {
                        console.log(err, 'user not admin');
                    });
            }
        };
        const getPermits = () => {
            if (user) {
                // console.log(user)
                fbDb.collection('licenses')
                    .get()
                    .then((querySnapshot) => {
                        let permitsList = [];
                        let pendingList = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();

                            if (data.licenseNumber) {
                                permitsList.push({ id, ...data });
                            } else if (!data.licenseNumber) {
                                pendingList.push({ id, ...data });
                            }
                        });
                        PermitsStore.update((s) => {
                            s.permits = permitsList;
                        });
                        PermitsStore.update((s) => {
                            s.pending = pendingList;
                        });
                    });
            }
        };
        const getPermitTypes = () => {
            // console.log(user)
            fbDb.collection('municipality')
                .doc('Brooks')
                .collection('permitTypes')
                .get()
                .then((querySnapshot) => {
                    const types = [];
                    querySnapshot.forEach((doc) => {
                        const id = doc.id;
                        const data = doc.data();
                        // console.log(doc.id, ' => ', doc.data());
                        types.push({ id, ...data });
                    });
                    PermitsStore.update((s) => {
                        s.permitTypes = types;
                    });
                })
                .catch((error) => console.log(error));
        };
        const getAlerts = () => {
            // console.log(user)
            fbDb.collection('alerts')
                .get()
                .then((querySnapshot) => {
                    const alertsList = [];
                    querySnapshot.forEach((doc) => {
                        const id = doc.id;
                        const data = doc.data();
                        // console.log(doc.id, ' => ', doc.data());
                        alertsList.push({ id, ...data });
                    });
                    TownStore.update((s) => {
                        s.alerts = alertsList;
                    });
                })
                .catch((error) => console.log(error));
        };
        getUser();
        getAlerts();
        getPermits();
        getPermitTypes();
        getTown();
        setTimeout(() => {
            setReady(true);
        }, 2000);
    }, [user]);

    if (loading) {
        return (
            <IonApp>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={3000}
                />
            </IonApp>
        );
    }

    if (error) {
        return (
            <IonApp>
                <div>
                    <p>Error: {error}</p>
                </div>
            </IonApp>
        );
    }

    if (!user) {
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet id='main'>
                        <Redirect from='/' to='/login' exact />
                        <Redirect from='/dashboard' to='/login' />
                        <Redirect from='/permits' to='/login' />
                        <Redirect from='/settings' to='/login' />
                        <Redirect from='/pending' to='/login' />
                        <Redirect from='/alerts' to='/login' />
                        <Redirect from='/announcements' to='/login' />
                        <Route root path='/login' component={Login} exact />
                        <Route root path='/forgot' component={Forgot} exact />
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }

    if (user && admin === false) {
        if (!ready) {
            return (
                <IonApp>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Please wait...'}
                        duration={600}
                    />
                </IonApp>
            );
        } else {
            return (
                <IonApp>
                    <IonContent>
                        <IonHeader collapse='condense'>
                            <IonToolbar>
                                <IonTitle size='large'>Nope.</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <p className='ion-padding ion-text-center'>
                            You do not have permission to access this page. If you believe this to
                            be an error, please contact your administrator.
                        </p>
                        <p className='ion-padding ion-text-center'>
                            <IonButton onClick={() => logout()}>Log Out</IonButton>{' '}
                        </p>
                    </IonContent>
                </IonApp>
            );
        }
    }
    if (user && admin) {
        if (!ready) {
            return (
                <IonApp>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Please wait...'}
                        duration={1000}
                    />
                </IonApp>
            );
        } else {
            return (
                <IonApp>
                    <IonReactRouter>
                        <IonSplitPane contentId='main'>
                            <Menu />
                            <IonRouterOutlet id='main'>
                                <Route path='/pending' component={Pending} exact />
                                <Route path='/licenses' component={Permits} exact />
                                <Route path='/alerts' component={Alerts} exact />
                                <Route path='/new-permit' component={New} exact />
                                {/* <Route path='/settings' component={Settings} exact /> */}
                                <Route path='/announcements' component={Announcements} exact />
                                <Route path='/success/:id' component={Success} />
                                <Route path='/licenses/:id' component={Permit} />
                                <Route path='/alerts/:id' component={Details} />
                                <Route path='/customers' component={Customers} exact />
                                <Redirect from='/dashboard' to='/pending' exact />
                                <Redirect from='/' to='/pending' exact />
                                <Redirect from='/login' to='/pending' exact />
                            </IonRouterOutlet>
                        </IonSplitPane>
                    </IonReactRouter>
                </IonApp>
            );
        }
    }
};

export default App;
