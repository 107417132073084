import {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonModal,
    IonIcon,
} from '@ionic/react';

import { PermitsStore } from '../store/PermitsStore';
import { PetStore } from '../store/PetStore';
import OnboardingSteps from './PetOnboarding/PetOnboardingSteps';
import React from 'react';
import './Menu.css';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';

const PermitModal = () => {
    const permitModal = PermitsStore.useState((s) => s.permitModal);
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const context = PetStore.useState((s) => s.context);

    const close = () => {
        PetStore.update((s) => {
            s.petOnboardingModal = false;
        });
    };
    const updateStep = (inc) => {
        if (inc !== 0) {
            PetStore.update((s) => {
                s.petOnboardingStep = s.petOnboardingStep + inc;
            });
        } else {
            PetStore.update((s) => {
                s.petOnboardingStep = 0;
            });
        }
    };
    return (
        <IonModal
            mode={'ios'}
            isOpen={permitModal}
            onDidDismiss={() => {
                PermitsStore.update((s) => {
                    s.permitModal = !s.permitModal;
                    s.newPermit = {};
                });
            }}
        >
            <IonHeader>
                <IonToolbar>
                    {step > 1 && step < 6 ? (
                        <IonButtons>
                            <IonButton onClick={() => updateStep(-1)}>
                                <IonIcon icon={chevronBackOutline} slot='start' />
                                Back
                            </IonButton>
                        </IonButtons>
                    ) : null}
                    <IonTitle>{context === 'update' ? 'Update' : 'Add'} a Pet</IonTitle>
                    {step !== 6 ? (
                        <IonButtons slot='end'>
                            <IonButton
                                onClick={() => {
                                    updateStep(0);
                                    close();
                                }}
                            >
                                <IonIcon icon={closeOutline} slot='icon-only' />
                            </IonButton>
                        </IonButtons>
                    ) : null}
                </IonToolbar>
            </IonHeader>
            <OnboardingSteps />
        </IonModal>
    );
};

export default PermitModal;
