import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { toast } from './components/toast';

const firebaseConfig = {
    apiKey: 'AIzaSyAzouXsjJ6UGQpEfWHynoPV9FCuYEvPdQw',
    authDomain: 'petlicenseonlineapp.firebaseapp.com',
    projectId: 'petlicenseonlineapp',
    storageBucket: 'petlicenseonlineapp.appspot.com',
    messagingSenderId: '623471360072',
    appId: '1:623471360072:web:5bda34b4b840c05847807d',
    measurementId: 'G-Q52BN8X50T',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();
export default firebaseApp;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export async function loginUser(email, password) {
    try {
        const res = await firebase.auth().signInWithEmailAndPassword(email, password);
        console.log(res);
        return true;
    } catch (error) {
        toast(error.message, 4000);
        return false;
    }
}

export function logoutUser() {
    return firebase.auth().signOut();
}
