import {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonMenuButton
  } from '@ionic/react';
  
  import React from 'react';
  import { useAuthState } from 'react-firebase-hooks/auth';
  import firebaseApp, { logoutUser } from '../firebase';
  import './Menu.css';

  const HeaderBar = (props) => {

    const [ user ] = useAuthState(firebaseApp.auth());
    return (
    
    <IonHeader>
        <IonToolbar mode="ios" color="primary">
            {user ?
            <IonButtons slot="start">
                <IonMenuButton />
            </IonButtons>
            : null
            }
            <IonTitle>{props.title}</IonTitle>
            {user ?
            <IonButtons slot="end">
                <IonButton onClick={logoutUser}>Log Out</IonButton>
            </IonButtons>
            : null}
        </IonToolbar>
   
    </IonHeader>
    )
  }

export default HeaderBar;