import {
    IonContent,
    IonCard,
    IonPage,
    IonRow,
    IonCol,
    IonGrid,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonLoading,
    IonButton,
    IonChip,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Login.css';
// import { useHistory } from 'react-router';
import HeaderBar from '../components/HeaderBar';

import firebaseApp from '../firebase';

const fbDb = firebaseApp.firestore();

const Details = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const [permit, setPermit] = useState(null);
    const getData = () => {
        setIsLoading(true);
        fbDb.collection('alerts')
            .doc(props.match.params.id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    const id = doc.id;
                    const data = doc.data();
                    setPermit({ id, ...data });
                    console.log({ id, ...data });
                } else {
                    // doc.data() will be undefined in this case
                    console.log('No such document!');
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('Error getting document:', error);
            });
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const markFound = () => {
        fbDb.collection('alerts')
            .doc(props.match.params.id)
            .update({ lost: false })
            .then(() => {
                fbDb.collection('users')
                    .doc(permit.user.uid)
                    .collection('pets')
                    .doc(permit.pet.id)
                    .update({ lost: false });
            })
            .then(() => {
                getData();
            })
            .catch((error) => {
                console.log('Error getting document:', error);
            });
    };
    if (permit) {
        return (
            <IonPage>
                <IonLoading isOpen={isLoading} />
                <HeaderBar title={permit && permit.pet.name + ' ' + permit.user.lastName} />
                <IonContent>
                    <IonGrid className='contentArea'>
                        <IonRow>
                            <IonCol>
                                <IonCard mode='ios' style={{ maxWidth: 500, margin: '20px auto' }}>
                                    <img
                                        style={{ width: '100%' }}
                                        src={`${permit.pet.image}`}
                                        alt={'pet'}
                                    />
                                    <p className='ion-text-center'>
                                        <IonChip color={permit.lost ? 'danger' : 'success'}>
                                            {permit.lost ? 'Lost' : 'Safe'}
                                        </IonChip>
                                    </p>
                                    <IonCardHeader className='ion-margin-top'>
                                        <IonCardTitle>
                                            {permit && permit.pet.name + ' ' + permit.user.lastName}
                                        </IonCardTitle>
                                        <IonCardSubtitle
                                            style={{ fontSize: '1.25rem', marginTop: 10 }}
                                        >
                                            {permit.pet.species}, {permit.pet.age} years old.
                                        </IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol>
                                                    <IonCardSubtitle style={{ fontSize: '0.8rem' }}>
                                                        Description
                                                    </IonCardSubtitle>
                                                    <p>
                                                        {permit.pet.breed +
                                                            ', ' +
                                                            permit.pet.identifiers}
                                                    </p>
                                                    <p>
                                                        Spayed/Neutered:{' '}
                                                        {permit.pet.neutered ? 'Yes' : 'No'}
                                                    </p>
                                                    <p>
                                                        Chipped:{' '}
                                                        {permit.pet.microchip ? 'Yes' : 'No'}
                                                    </p>
                                                </IonCol>
                                                <IonCol>
                                                    <IonCardSubtitle style={{ fontSize: '0.8rem' }}>
                                                        Owner Information
                                                    </IonCardSubtitle>
                                                    <p>
                                                        {permit.user.firstName +
                                                            ' ' +
                                                            permit.user.lastName}
                                                    </p>
                                                    <p>{permit.user.address}</p>
                                                    <p>
                                                        <a href={`mailto:${permit.email}`}>
                                                            {permit.user.email}
                                                        </a>
                                                    </p>
                                                    <p>{permit.user.phone}</p>
                                                </IonCol>
                                            </IonRow>
                                            <div style={{ height: 20 }} />
                                            <IonRow></IonRow>
                                            <div style={{ height: 20 }} />
                                            <IonRow>
                                                <IonCol>
                                                    <IonCardSubtitle style={{ fontSize: '0.8rem' }}>
                                                        Veterinarian Information
                                                    </IonCardSubtitle>
                                                    <p>{permit.pet.vetName}</p>
                                                    <p>{permit.pet.vetAddress}</p>
                                                </IonCol>
                                            </IonRow>
                                            {permit.lost ? (
                                                <IonRow className='ion-margin-top'>
                                                    <IonCol>
                                                        <IonButton
                                                            expand='full'
                                                            onClick={() => markFound()}
                                                        >
                                                            Mark {permit.pet.name} as found
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            ) : null}
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    } else {
        return <IonLoading />;
    }
};

export default Details;
