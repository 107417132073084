import { Store } from 'pullstate';

export const PermitsStore = new Store({
    permits: null,
    terms: null,
    permitTypes: [],
    pending: null,
    initialApplicationState: {
        type: null,
        address: null,
        price: null,
        pet: null,
    },
    application: {
        type: null,
        address: null,
        price: null,
        pet: null,
        email: '',
    },
    activities: [],
    permitModal: false,
    newPermit: {},
});
