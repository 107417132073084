import {
    IonItem,
    IonInput,
    IonButton,
    IonIcon,
    IonImg,
    IonActionSheet,
    IonAlert,
} from '@ionic/react';
import React, { useState } from 'react';
/* Pullstate Store */
import { PetStore } from '../../../store/PetStore';
import { usePhotoGallery } from '../../../hooks/usePhotoGallery';
import { bulbSharp, cameraOutline, close, trash } from 'ionicons/icons';
import { TownStore } from '../../../store/TownStore';
import { firestore } from '../../../firebase';
import { UserStore } from '../../../store/UserStore';
import { toast } from '../../Toast/Toast';
const Step1 = () => {
    const user = UserStore.useState((s) => s.user);
    const userDoc = UserStore.useState((s) => s.userDoc);
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const petDoc = PetStore.useState((s) => s.petDoc);
    const context = PetStore.useState((s) => s.context);
    const townDoc = TownStore.useState((s) => s.townDoc);
    const { deletePhoto, takePhoto, photos } = usePhotoGallery();
    const [photoToDelete, setPhotoToDelete] = useState();
    const [passingAlert, setPassingAlert] = useState(false);
    const updateUserDoc = (e) => {
        PetStore.update((s) => {
            s.petDoc = { ...s.petDoc, name: e.target.value };
        });
    };
    const handlePassing = () => {
        firestore
            .collection('users')
            .doc(user.uid)
            .collection('pets')
            .doc(petDoc.id)
            .update({
                deceased: true,
                user: userDoc,
                licensed: false,
            })
            .then(() => {
                toast(`Updated`, 'success');
                PetStore.update((s) => {
                    s.petOnboardingModal = false;
                    s.petDoc = s.initialPetDoc;
                    s.petOnboardingStep = 0;
                });
            })
            .then(() => {
                const getUserPets = () => {
                    // console.log(user)
                    firestore
                        .collection('users')
                        .doc(user.uid)
                        .collection('pets')
                        .get()
                        .then((querySnapshot) => {
                            const petsList = [];
                            querySnapshot.forEach((doc) => {
                                const data = doc.data();
                                const id = doc.id;
                                petsList.push({ id, ...data });
                                // doc.data() is never undefined for query doc snapshots
                                console.log(doc.id, ' => ', doc.data());
                            });
                            console.log(petsList);
                            PetStore.update((s) => {
                                s.pets = petsList;
                            });
                        })
                        .catch((error) => console.log(error));
                };
                getUserPets();
            })
            .catch((error) => {
                console('Update pet failed', error);
            });
    };
    return (
        <div className={step === 1 ? null : 'ion-hide'}>
            <h2 className='ion-padding onboardingTitles'>Add pet photo if possible</h2>
            <div
                style={{
                    borderRadius: petDoc.image ? 10 : '50%',
                    width: petDoc.image ? '80%' : 150,
                    height: petDoc.image ? 'auto' : 150,
                    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.25)',
                    backgroundImage: petDoc.image ? petDoc.image : null,
                    backgroundColor: petDoc.image ? null : '#3880ff',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: petDoc.image ? '100%' : 'auto 300px',
                    backgroundPosition: 'center',
                    overflow: 'hidden',
                    margin: '40px auto 20px',
                }}
                onClick={() => (petDoc.image ? setPhotoToDelete(photos[0]) : takePhoto('pet'))}
            >
                {!petDoc.image ? (
                    <p className='ion-text-center' style={{ margin: 'auto', color: '#fff' }}>
                        <>
                            <IonIcon
                                icon={cameraOutline}
                                style={{ color: '#fff', fontSize: 50, marginTop: 40 }}
                            />
                            <br />
                            <small>Optional</small>
                        </>
                    </p>
                ) : (
                    <IonImg src={petDoc.image} />
                )}
            </div>
            <div style={{ height: '20px' }} />
            <IonItem lines='none'>
                <IonInput
                    type='text'
                    style={{ textAlign: 'center' }}
                    autocapitalize='on'
                    placeholder={`Enter the pet's name here`}
                    className='onboardingInputs'
                    enterkeyhint='next'
                    inputmode='text'
                    pattern='text'
                    value={petDoc.name || ''}
                    onIonChange={(e) => updateUserDoc(e)}
                />
            </IonItem>

            <IonButton
                disabled={!petDoc.name ? true : false}
                className='ion-margin'
                shape='round'
                expand='block'
                onClick={() => {
                    PetStore.update((s) => {
                        s.petOnboardingStep = 2;
                    });
                }}
            >
                {'Continue'}
            </IonButton>
            {context === 'update' ? (
                <div className='ion-padding'>
                    <p className='ion-text-center'>Has {petDoc && petDoc.name} recently passed?</p>
                    <IonButton
                        disabled={!petDoc.name ? true : false}
                        className='ion-margin'
                        shape='round'
                        expand='block'
                        fill='outline'
                        onClick={() => {
                            setPassingAlert(true);
                        }}
                    >
                        {`Cancel ${petDoc.name}'s license`}
                    </IonButton>
                </div>
            ) : null}
            <div className='ion-padding'>
                <p className='ion-text-left'>
                    <IonIcon icon={bulbSharp} color='warning' />
                    <strong>Hint:</strong> Tap the Camera icon above to take or upload a photo of
                    your pet. If you want to retake your photo, you can tap on it and then choose
                    "Delete Photo" to begin again.
                </p>
            </div>
            <div className='ion-padding-horizontal'>
                <p className='ion-text-left'>
                    <strong>Why do we collect this information?</strong>
                </p>
                <p className='ion-text-left'>
                    We ask for a photo of your pet to help with identifying your pet within this
                    application. {townDoc.fullName} staff may also use this photo for identification
                    should your pet be reported as missing.
                </p>
            </div>

            <IonActionSheet
                isOpen={!!photoToDelete}
                buttons={[
                    {
                        text: 'Delete Photo',
                        role: 'destructive',
                        icon: trash,
                        handler: () => {
                            if (photoToDelete) {
                                deletePhoto(photoToDelete);
                                setPhotoToDelete(undefined);
                            }
                        },
                    },
                    {
                        text: 'Cancel',
                        icon: close,
                        role: 'cancel',
                    },
                ]}
                onDidDismiss={() => setPhotoToDelete(undefined)}
            />
            <IonAlert
                isOpen={passingAlert}
                onDidDismiss={() => setPassingAlert(false)}
                header={'Our condolences'}
                message={`Please accept our condolences on the passing of ${petDoc.name}. We're sorry for your loss. By clicking confirm below, we will remove ${petDoc.name} from your list of registered pets and cancel any active license subscriptions. `}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Confirm Cancel');
                        },
                    },
                    {
                        text: 'Confirm',
                        handler: () => handlePassing(),
                    },
                ]}
            />
        </div>
    );
};

export default Step1;
