import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonButton,
    IonIcon,
    IonChip,
} from '@ionic/react';
import React from 'react';
import MUIDataTable from 'mui-datatables';
import './Page.css';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router';
import { eyeOutline, mailOutline, trashBinSharp } from 'ionicons/icons';
import dayjs from 'dayjs';
import { TownStore } from '../store/TownStore';
import firebaseApp from '../firebase';

const fbDb = firebaseApp.firestore();
const Alerts = () => {
    const history = useHistory();
    const alerts = TownStore.useState((s) => s.alerts);

    const options = {
        filterType: 'multiselect',
        elevation: 0,
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        },
        selectableRows: 'none',
        customToolbarSelect: () => {},

        // filename: 'permits.csv'
    };
    const handleClick = (rowData) => {
        // console.log(rowData);
        TownStore.update((s) => {
            s.activeAlert = rowData;
        });
        history.push(`/alerts/${rowData.id}`);
    };

    const handleDelete = (rowData) => {
        fbDb.collection('alerts')
            .doc(rowData.id)
            .delete()
            .then(() => {
                fbDb.collection('alerts')
                    .get()
                    .then((querySnapshot) => {
                        const alertsList = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();
                            // console.log(doc.id, ' => ', doc.data());
                            alertsList.push({ id, ...data });
                        });
                        TownStore.update((s) => {
                            s.alerts = alertsList;
                        });
                    })
                    .catch((error) => console.log(error));
            });
    };
    const columns = [
        {
            name: 'lost',
            label: 'Status',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val, data) =>
                    data.tableData[data.rowIndex].lost === true ? (
                        <IonChip color='danger'>Lost</IonChip>
                    ) : (
                        <IonChip color='success'>Safe</IonChip>
                    ),
            },
        },
        {
            name: 'read',
            label: 'View',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, data) => (
                    <IonButton
                        fill='clear'
                        color={'primary'}
                        onClick={() => {
                            // console.log(value, data);
                            handleClick(data.tableData[data.rowIndex]);
                        }}
                        slot='icon-only'
                    >
                        <IonIcon icon={eyeOutline} />
                    </IonButton>
                ),
            },
        },
        {
            name: 'pet.name',
            label: 'Pet Name',
            options: {
                display: true,
                filter: false,
                sort: false,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].pet.name,
            },
        },
        {
            name: 'pet.type',
            label: 'Pet Type',
            options: {
                display: true,
                filter: false,
                sort: false,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].pet.species,
            },
        },
        {
            name: 'phone',
            label: 'Phone',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].user.phone,
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, data) =>
                    data.tableData[data.rowIndex].user.email ? (
                        <IonButton
                            fill='clear'
                            href={`mailto:${data.tableData[data.rowIndex].user.email}`}
                        >
                            <IonIcon icon={mailOutline} />
                        </IonButton>
                    ) : (
                        'Unavailable'
                    ),
            },
        },
        {
            name: 'address',
            label: 'Location',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].user.address,
            },
        },
        {
            name: 'firstName',
            label: 'First Name',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].user.firstName,
            },
        },
        {
            name: 'lastName',
            label: 'Last Name',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (val, data) => data.tableData[data.rowIndex].user.lastName,
            },
        },
        {
            name: 'reportedTime',
            label: 'Created',
            options: {
                filter: false,
                display: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    let val = alerts[dataIndex];
                    return dayjs(val.reportedTime).format('MMM DD, YYYY hh:mm a');
                },
            },
        },
        {
            name: 'read',
            label: 'Delete',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, data) => (
                    <IonButton
                        fill='clear'
                        color={'primary'}
                        onClick={() => {
                            // console.log(value, data);
                            handleDelete(data.tableData[data.rowIndex]);
                        }}
                        slot='icon-only'
                    >
                        <IonIcon color='danger' icon={trashBinSharp} />
                    </IonButton>
                ),
            },
        },
        {
            name: 'user',
            label: 'user',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        },
    ];

    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar color='primary'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Pet Alerts</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Pet Alerts</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <MUIDataTable
                                    title={'Pet Alerts'}
                                    data={alerts}
                                    columns={columns}
                                    options={options}
                                />
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Alerts;
